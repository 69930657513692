import {useState, useEffect} from 'react';
import {useNavigate, useLocation} from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
// @mui
import { Grid, Button, Container, Stack, Typography, IconButton } from '@mui/material';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
// components
import Iconify from '../components/iconify';
import { BlogPostCard2, BlogPostsSort, BlogPostsSearch } from '../sections/@dashboard/blog';
// mock
import POSTS from '../_mock/blog';
import Header from 'src/layouts/dashboard/header';
import ReactMarkdown from 'react-markdown';
import axios from 'axios';
import { useTranslation, initReactI18next } from 'react-i18next';
import i18n from 'i18next';
// Import your translation files
import enTranslation from '../locals/en.json';
import cnTranslation from '../locals/cn.json';
import frTranslation from '../locals/fr.json';
import jpTranslation from '../locals/jp.json';

// ----------------------------------------------------------------------



  // Initialize i18next
  i18n.use(initReactI18next).init({
    resources: {
      en: {
        translation: enTranslation,
      },
      cn: {
        translation: cnTranslation,
      },
      fr: {
        translation: frTranslation,
      },
      jp: {
        translation: jpTranslation,
      }
    },
    lng: 'en', // Set the default language
    fallbackLng: 'en', // Fallback language if translation is not available
  });

// ----------------------------------------------------------------------

export default function BlogPage(props) {
  const navigate = useNavigate();

  const SORT_OPTIONS = [
    { value: 'latest', label: "New" },
    { value: 'oldest', label: "Old" },
  ];

  useEffect(() => {
    
    localStorage.setItem("currentLanguage","en");
    // i18n.changeLanguage("en");

    const disableUserScaling = () => {
      const viewportMetaTag = document.querySelector('meta[name="viewport"]');
      viewportMetaTag.content = 'width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no';
    };

    disableUserScaling();
  } , []);
  const [sortingOrder, setSortingOrder] = useState('latest');


  const [openDeleteAllPostsDialog,setOpenDeleteAllPostsDialog] = useState(false);





  const jumpToLoginPage = () => {
    navigate('/loginfDFewfDfeDFSFFue738JDuewifDueufsd89rjudef7UEr3457esfd');
  }


  const handleSearching = (keyword) => {
    props.handleKeywordSearch(keyword);
    // window.alert(keyword);
    // replace spaces or multiple consecutive spaces with a hyphen character
    
  }

  const sortByDate = (selectedSortingOrder) => {
    setSortingOrder(selectedSortingOrder);
    props.handleSortingBlogs(selectedSortingOrder);
  }


  const handleDeleteAllPosts = () => {
    setOpenDeleteAllPostsDialog(true);
    // window.alert("adsf");
    
  }

  const handleCloseDeleteAllPostsDialog = () => {
    setOpenDeleteAllPostsDialog(false);
  }

  const confirmDeletingAllPosts = () => {
    let currentLanguage = "en";
    fetch(`${process.env.REACT_APP_API_URL}/posts/deleteAll?language=` + currentLanguage)
      .then(response => response.text())
      .then(data => {
        window.alert(data);
        setOpenDeleteAllPostsDialog(false);
        navigate(`/`);
      })
      .catch(error => console.log(error));
  }


  const handleShowPostDetails = (id) => {
    // Save the last scrolled postion when the user left
    props.saveScrolledPosition();

    // When jumping to the post detail page, we need to carray the current posts data
    // so that when we come back, those posts are still there
    const clickedCategory = props.currentCategoryName;
    const previousPosts = props.blogPosts;
    navigate(`/showPostDetails/${id}`, { state: { previousPosts, clickedCategory } });
  };



  return (
    <>
       
      <Helmet>
        <title> Uniqueman DEV</title>
      </Helmet>

      <Container>
      <div style={{height:"100px"}}></div>
        <Stack direction="row" alignItems="center"  mb={5}>
          <IconButton
            onClick={props.onOpenNav}
            sx={{
              mr: 1,
              mb: 1,
              color: 'text.primary',
              // display: { lg: 'none' },
            }}
          >
            <Iconify icon="eva:menu-2-fill" />
          </IconButton>

          <Typography variant="h4" gutterBottom>
            {props.currentCategoryName}
          </Typography>
          {/* Only show the "New Post" button when the admin logs in */}
          {/* 2024-05-17:For narmal users, don't show anything at all.The login page can't be accessed by normal users by clicking the button.It should only be accessed via the private link.
                  <Button 
                    variant="contained" 
                    startIcon={<Iconify icon="eva:plus-fill" />}
                    onClick={jumpToLoginPage}>
                      Log In
                  </Button>
          */}
          
            {/* 2024-05-17: We don't want the DeleteAll Button anymore, because we can absolutely delete all posts from the side menu panel, no need for this button */}
              {/* localStorage.getItem("userLoggingInfo") 
              && <div>
                    
                    <Button 
                        variant="contained" 
                        onClick={handleDeleteAllPosts}>
                          Delete All
                    </Button>
                  </div>
              || <></> */}
            
          
        </Stack>
        {/* The following dialog is used for comfirmation when the user tries to delete all posts. */}
        <Dialog open={openDeleteAllPostsDialog} onClose={handleCloseDeleteAllPostsDialog}>
          <DialogTitle>Delete All Posts</DialogTitle>
          <DialogContent>
            <DialogContentText>
            If you want to delete all post,please click Confirm button.Once confirmed,it's unretrievable.You are warned.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDeleteAllPostsDialog}>Cancel</Button>
            <Button onClick={confirmDeletingAllPosts}>Confirm</Button>
          </DialogActions>
        </Dialog>

        <Stack mb={5} direction="row" alignItems="center" justifyContent="space-between">
          <div style={{marginRight:"15px"}}>
            <BlogPostsSearch handleSearchingPost={handleSearching}/>
          </div>
          <div>
            <BlogPostsSort options={SORT_OPTIONS} sortByDate={sortByDate} sortingOrder={sortingOrder} />
          </div>
        </Stack>

        <Grid container spacing={3}>
          {props.blogPosts.map((post, index) => (
            <BlogPostCard2 key={post.id} post={post} index={index} onShowPostDetails={handleShowPostDetails}/>
            
          ))}
        </Grid>
       
        <Stack mb={5} direction="row" alignItems="center" style={{marginTop:"100px",height:"100px",justifyContent:"center"}}></Stack>
      </Container>
      
    </>
  );
}
